<template>
  <MasterDetail
    formTitle="Cadastro de Item NF de Materiais"
    :cols="cols"
    :opts="opts"
    :customResource="customResource"
    descriptionProperty="descricao"
    @onOpenFormDialog="onOpenFormDialog"
  >
    <span v-show="valorNFe" style="font-size:16px">
      <strong>Valor utilizado da nota:</strong>
      {{ valorTotalItens | toCurrency }}
      <small>de</small>
      {{ valorNFe | toCurrency }}
    </span>
  </MasterDetail>
</template>

<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    MasterDetail,
  },
  created: function () {
    this.resourceUnidades.get().then(response => {
      this.opts.unidades = response;
    });
    this.resourceNFe.then(response => {
      this.nf = response;
      const query = `ano=${response.competencia.substring(0, 4)}`;
      this.resourceProjetos.get({ query }).then((response) => {
        this.opts.projetos = response.projetos;
      });
    });
  },
  computed: {
    ...mapGetters(['clientId']),
    resource: function () {
      return this.apiResource(
        `/v1/fiscal/nfmaterial/${this.clientId}/${this.$route.params.nfId}/itens`
      );
    },
    customResource: function () {
      return {
        ...this.resource,
        get: (param) => {
          return new Promise((resolve, reject) => {
            this.resource
              .get(param)
              .then((result) => {
                this.valorTotalItens = result.reduce((acc, { valor }) => acc + valor, 0);
                resolve(result);
              })
              .catch(reject);
          });
        },
      };
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/selecao`);
    },
    resourceUnidades: function () {
      return this.apiResource(`/v1/clientes/${this.clientId}/unidades`);
    },
    resourceNFe: function () {
      const nfId = this.$route.params.nfId;
      return this.apiResource(`/v1/fiscal/nfmaterial/${this.clientId}`).get({
          query: `nfId=${nfId}`,
        });
    },
    valorNFe: function () {
      return this.nf ? this.nf.valor_nf : 0;
    },
    cols: function () {
      const basicCols = [
        {
          key: "id",
          name: "ID",
          align: 1,
          hideInform: true,
          type: 0,
        },
        {
          key: "projetoId",
          name: "Projeto",
          align: -1,
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "projetos", key: "id", name: "titulo" },
          valueChanged: (value) => this.setFinalidades(value),
        },
        {
          key: "trabalho",
          name: "Benefício",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "servicos", key: "value", name: "text" },
          editable: this.projetoSelecionado.trabalho == 3,
          valueChanged: (value) => (this.servicoDoProjetoSelecionado = value),
          colSize: 6,
        },
        {
          key: "unidadeId",
          name: "Unidade",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: { to: "unidades", key: "id", name: "unidade" },
          colSize: 6,
        },
        {
          key: "finalidade",
          name: "Classificação",
          type: this.$fieldTypes.AUTOCOMPLETE,
          rules: [{ rule: "required" }],
          rel: {
            toEdit: "finalidades",
            to: "todasfinalidades",
            key: "value",
            name: "value",
          },
        },
        {
          key: "descricao",
          name: "Descrição",
          align: -1,
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
        },
        {
          key: "quantidade",
          name: "Quantidade",
          align: -1,
          type: this.$fieldTypes.NUMBER,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor Total",
          align: 1,
          type: this.$fieldTypes.MONEY,
          nowrap: true,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "justificativa",
          name: "Justificativa",
          align: -1,
          type: this.$fieldTypes.TEXTAREA,
          rules: [{ rule: "required"}, { rule: "max", params: { size: 1000 }}]
        },
        {
          key: "criado_em",
          name: "Criado Em",
          type: this.$fieldTypes.DATETIME,
          hideInform: true,
          hideInTable: true,
        },
        {
          key: "modificado_em",
          name: "Modificado Em",
          align: 1,
          hideInform: true,
          type: this.$fieldTypes.DATETIME,
        },
      ];

      return basicCols;
    },
  },
  methods: {
    onOpenFormDialog: function (selectedItem) {
      this.setFinalidades(selectedItem.projetoId);
      this.projetoSelecionado = selectedItem || {
        trabalho: null,
      };
    },
    setFinalidades: function (projetoId) {
      const projeto = this.opts.projetos.find((p) => p.id == projetoId);
      this.opts.finalidades = projeto
        ? this.opts.todasfinalidades.filter((f) =>
            f.trabalho.includes(projeto.trabalho)
          )
        : [];
      this.projetoSelecionado.trabalho = projeto ? projeto.trabalho : null;
      this.servicoDoProjetoSelecionado = projeto ? projeto.trabalho : null;
    },
  },
  data: function () {
    return {
      servicoDoProjetoSelecionado: 0, // Necessário para forçar o campo Serviço de atualizar seu estado de editável ou não
      nf: null,
      valorTotalItens: 0,
      projetoSelecionado: {
        trabalho: null,
      },
      opts: {
        projetos: [],
        finalidades: [],
        unidades: [],
        todasfinalidades: [
          { value: "EQUIPAMENTOS E SOFTWARE", trabalho: [2, 3] },
          { value: "MATERIAL DE CONSUMO", trabalho: [1, 2, 3] },
          { value: "MATERIAL PARA PROTÓTIPO", trabalho: [1, 2, 3] },
          { value: "LIVROS E PERIÓDICOS", trabalho: [2, 3] },
        ],
        servicos: [
          { text: "LEI DO BEM", value: 1 },
          { text: "LEI DE INFORMÁTICA", value: 2 },
          { text: "AMBOS", value: 3 },
        ],
      },
    };
  },
};
</script>
